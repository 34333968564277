<template>
    <div class="about">
        <!-- <banner></banner> -->
        <!-- <bread-page> -->
        <div class="banner_top">
            <el-image fit="cover" src="https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-10-24-16-13-06MXJmzqmv.jpg" class="about_img"/>
            <div class="banner_top_title">关于我们</div>
        </div>
        <div class="intro" id="intro">
            <divider-title>
                <!-- <template> 平台 </template> -->
                <template #prim>中心简介</template>
                <template #sub>Platform Overview</template>
            </divider-title>
            <div class="intro_con">
                <div class="text" v-html="connect"></div>
                <div class="intro_con_right">
                    <swiper :options="honorOption">
                        <swiper-slide v-for="(item, index) in honorOptionImage" :key="index">
                            <el-image fit="fill" :src="item.img" class="intro_con_right_img"/>
                        </swiper-slide>
                    </swiper>
                    <!-- <img src="../assets/bianzu3@3x.png" alt="" class="intro_con_right_img" /> -->
                    <div class="intro_con_right_one"></div>
                    <div class="intro_con_right_two"></div>
                    <div class="intro_con_right_three"></div>
                </div>
            </div>
        </div>
        <!-- </bread-page> -->
        <!-- <div class="history" ref="history" id="history">
            <div class="history_con">
                <divider-title>
                    <template>发展</template>
                    <template #prim>历程</template>
                    <template #sub>Development process</template>
                </divider-title>
                <swiper ref="imgSwiper" class="content" :options="imgOptions">
                    <swiper-slide v-for="(item, index) in historys" :key="index" class="content_con">
                        <img :src="item.img" alt="" class="content_con_img" />
                        <div class="content_con_title">
                            <div class="content_con_title_one">
                                <span>{{ formatTime(item.time, 'YYYY') }}</span>
                                <span>年</span>
                                <span>{{ formatTime(item.time, 'MM月') }}</span>
                            </div>
                            <div class="content_con_title_two" v-html="activeHistory.title"></div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="content_subscript">
                    <div class="content_subscript_con" v-for="(item, index) in historys" :key="index">
                        <div
                            :class="index === actives ? 'content_subscript_con_titles' : 'content_subscript_con_title'"
                        >
                            <span>{{ formatTime(item.time, 'YYYY') }}</span>
                            <span>.</span>
                            <span>{{ formatTime(item.time, 'MM') }}</span>
                        </div>
                        <img :src="index === actives ? select : unchecked" alt="" class="content_subscript_con_img" />
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="center-content honor-content" id="honor">
            <divider-title>
                <template #prim>荣誉资质</template>
                <template #sub>Qualification honor</template>
            </divider-title>
            <swiper ref="honor" class="honor" :options="honorOptions">
                <swiper-slide v-for="(item, index) in honors" :key="index">
                    <img :src="item.img" alt="" />
                    <div class="name">{{ item.title }}</div>
                </swiper-slide>
            </swiper>
            <div class="el-icon-arrow-right" slot="button-next"></div>
            <div class="el-icon-arrow-left" slot="button-prev"></div>
        </div> -->

        <div class="connect" id="connect">
            <div class="center-content">
                <divider-title>
                    <template>联系</template>
                    <template #prim>我们</template>
                    <template #sub>contact us</template>
                </divider-title>

                <div class="connect-content">
                    <map-container></map-container>

                    <div class="text">
                        <div>
                            <i class="iconfont iconfont-footer_icon_dianhua"></i>
                            <span>{{ phone }}</span>
                        </div>
                        <div>
                            <i class="iconfont iconfont-footer_icon_youjian"></i>
                            <span>{{ email }}</span>
                        </div>
                        <div>
                            <i class="iconfont iconfont-footer_icon_dizhi"></i>
                            <span>{{ address }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import DividerTitle from '../components/DividerTitle.vue';
import BreadPage from '../components/page/BreadPage.vue';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import MapContainer from '../components/MapContainer.vue';
export default {
    components: { Banner, BreadPage, DividerTitle, Swiper, SwiperSlide, MapContainer },
    directives: {
        swiper: directive
    },
    data() {
        return {
            connect: '',
            select: require('../assets/xuanzhong@3x.png'),
            unchecked: require('../assets/weixuanzhong@3x.png'),
            historys: [],
            boxOptions: {
                direction: 'vertical',
                slidesPerView: 'auto',
                centeredSlides: true,
                pagination: {
                    type: 'progressbar',
                    el: '.time-pagination'
                },
                mousewheel: true,
                releaseOnEdges: true,
                autoplay: {
                    autoplay: false,
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }
            },
            imgOptions: {
                slidesPerView: 1,
                pagination: {
                    el: '.img-pagination',
                    type: 'fraction'
                },
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                on: {
                    transitionEnd: swiper => {
                        this.actives = this.imgSwiper.activeIndex;
                    }
                }
            },
            honorOption: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
            },
            honorOptions: {
                slidesPerView: 3,
                spaceBetween: 40,
                pagination: {
                    el: '.honor-pagination',
                    type: 'progressbar'
                },
                navigation: {
                    nextEl: '.el-icon-arrow-right',
                    prevEl: '.el-icon-arrow-left'
                },
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
            },
            active: 0,
            honors: [],
            address: '',
            email: '',
            phone: '',
            timer: null,
            actives: 0,
            honorOptionImage: [
                { img: 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-10-24-16-30-40VYXQJPpP.jpg' },
                { img: 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-10-24-16-30-37ZikakrPY.jpg' },
                { img: 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-10-24-16-30-31lgVgNQqy.jpg' },
                { img: 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-10-24-16-30-28EianhQHW.jpg' },
                { img: 'https://baixiaip.oss-cn-hangzhou.aliyuncs.com/image/2022-10-24-16-30-21ZJUyKUdf.jpg' }
            ]
        };
    },
    computed: {
        page() {
            return this.$refs['imgSwiper'].swiper;
        },
        imgSwiper() {
            return this.$refs.imgSwiper.$swiper;
        },
        timeSwiper() {
            return this.$refs.timeSwiper.$swiper;
        },
        activeHistory() {
            if (this.historys.length > this.active) {
                return [...this.historys][this.active];
            } else {
                return {
                    time: ''
                };
            }
        }
    },
    watch: {
        $route() {
            this.scrollEvent();
        }
    },
    mounted() {
        this.$http
            .get('/sysConfig/get/CONVERGENCE')
            .then(res => {
                this.connect = res.value;
                return this.$http.get('/sysConfig/get/PROCESS');
            })
            .then(res => {
                this.historys = JSON.parse(res.value);
                this.$nextTick(() => {
                    this.imgSwiper.init();
                    this.timeSwiper.update();
                    this.imgSwiper.controller.control = this.timeSwiper;
                    this.timeSwiper.controller.control = this.imgSwiper;
                    if (!this.timeSwiper.autoplay.running) {
                        this.timeSwiper.autoplay.start();
                    }
                    // setTimeout(() => {
                    //     this.imgSwiper.updateSize();
                    // }, 500);
                });
                return this.$http.get('/sysConfig/get/HONOR');
            })
            .then(res => {
                this.honors = JSON.parse(res.value);
                this.$nextTick(() => {
                    this.$refs.honor.$swiper.update();
                    if (!this.$refs.honor.$swiper.autoplay.running) {
                        this.$refs.honor.$swiper.autoplay.start();
                    }
                });

                this.$nextTick(() => {
                    this.scrollEvent();
                });
            });

        this.$http.get('/sysConfig/get/ADDRESS').then(res => {
            this.address = res.value;
        });
        this.$http.get('/sysConfig/get/EMAil').then(res => {
            this.email = res.value;
        });
        this.$http.get('/sysConfig/get/PHONE').then(res => {
            this.phone = res.value;
        });
    },
    methods: {
        scrollEvent() {
            if (this.$route.hash) {
                this.$nextTick(() => {
                    let top = document.getElementById(this.$route.hash.replace(/#/, '')).offsetTop;
                    window.scrollTo({
                        top: top - 100,
                        behavior: 'smooth'
                    });
                });
            }
        },
        touchStart() {
            if (this.timeSwiper.autoplay.running) {
                this.timeSwiper.autoplay.stop();
            }
        },
        touchEnd() {
            if (!this.timeSwiper.autoplay.running) {
                this.timeSwiper.autoplay.start();
            }
        },
        slideChange() {
            this.$nextTick(() => {
                this.active = this.imgSwiper.activeIndex;
            });

            let childs = this.$refs.historyTitle.children;

            childs[0].className = childs[0].className + ' animate__animated animate__lightSpeedInRight';
            childs[1].className = childs[1].className + ' animate__animated animate__flipInX animate__delay-1s';
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                childs[0].className = childs[0].className.replace(/ animate__animated animate__lightSpeedInRight/g, '');
                childs[1].className = childs[1].className.replace(
                    / animate__animated animate__flipInX animate__delay-1s/g,
                    ''
                );
            }, 2000);
        }
    }
};
</script>

<style lang="less" scoped>
.about {
    background: #ffffff !important;
    padding-bottom: 0px;
}
.about_img {
    width: 100%;
    height: 510px;
    margin-top: -90px;
}
.intro {
    max-width: 1300px;
    padding: 100px 50px 100px 50px;
    margin: 0 auto;
    box-sizing: border-box;
    .intro_con {
        display: flex;
        .intro_con_right {
            width: 360px;
            height: 360px;
            position: relative;
            margin-left: 80px;
            .intro_con_right_img {
                width: 360px;
                height: 360px;
            }
            .intro_con_right_one {
                width: 189px;
                height: 20px;
                background: #01a041;
                opacity: 0.6;
                position: absolute;
                right: -20px;
                top: -20px;
            }
            .intro_con_right_two {
                width: 20px;
                height: 169px;
                background: #01a041;
                opacity: 0.6;
                position: absolute;
                right: -20px;
                top: 0px;
            }
            .intro_con_right_three {
                width: 20px;
                height: 90px;
                background: #939599;
                opacity: 0.6;
                position: absolute;
                bottom: 0px;
                left: -20px;
            }
        }
    }
    .text {
        font-size: 14px;
        color: #292c33;
        line-height: 26px;
        text-indent: 30px;
    }
}

.history {
    background: #f8f8f8;
    position: relative;
    padding: 40px 0px;
    box-sizing: border-box;
    .history_con {
        max-width: 1300px;
        margin: 0 auto;
        padding: 0px 50px;
        box-sizing: border-box;
    }
    .content {
        width: 100% !important;
        .content_con {
            display: flex;
            margin-bottom: 50px;
            .content_con_img {
                width: 432px;
                height: 288px;
            }
            .content_con_title {
                flex-grow: 1;
                background: #ffffff;
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
                padding: 60px 40px 0px;
                .content_con_title_one {
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #01a041;
                    line-height: 24px;
                    margin-bottom: 16px;
                }
                .content_con_title_two {
                    font-size: 14px;
                    font-weight: 400;
                    color: #313233;
                    line-height: 24px;
                }
            }
        }
    }
    // .content {
    //     position: relative;
    //     background: #f8f8f8;
    //     box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.07);
    //     padding: 80px 0 300px;
    //     height: 700px;
    //     box-sizing: border-box;
    //     @media screen and (max-width: 1300px) {
    //         height: 500px;
    //     }
    // }
    // .center-content {
    //     padding: 100px 50px 60px;
    //     position: relative;
    //     z-index: 2;
    // }

    // .left {
    //     width: 600px;
    //     right: 50%;
    //     position: relative;
    //     padding-top: 172px;
    // }
    // .title {
    //     font-size: 20px;
    //     font-weight: bold;
    //     color: #393939;
    //     line-height: 30px;
    //     position: absolute;
    //     top: 80px;
    //     right: calc(50% + 200px);
    //     width: 320px;
    //     padding-left: 30px;
    //     z-index: 3;
    //     background-cloor: rgba(255, 255, 255, 0.4);
    //     padding: 10px;
    //     &::before {
    //         content: '';
    //         width: 2px;
    //         position: absolute;
    //         left: 0px;
    //         top: 10px;
    //         bottom: 10px;
    //         background-color: @prim;
    //     }

    //     .time {
    //         span {
    //             vertical-align: middle;
    //             &:first-child {
    //                 font-size: 24px;
    //                 font-family: Impact;
    //                 line-height: 40px;
    //             }

    //             &:nth-child(2) {
    //                 font-size: 22px;
    //                 font-weight: bold;
    //                 line-height: 30px;
    //                 padding: 0 6px 0 2px;
    //             }
    //         }
    //     }
    // }

    // .timeLine {
    //     width: 340px;
    //     height: 50%;
    //     padding: 20px;
    //     box-sizing: border-box;
    //     background-color: fade(@warn, 70%);
    //     position: absolute;
    //     right: calc(50% - 100px);
    //     bottom: 90px;

    //     /deep/ .swiper-slide {
    //         height: auto;
    //         padding: 10px 0 20px;
    //     }

    //     .time-item {
    //         font-size: 14px;
    //         color: #ffffff;
    //         line-height: 24px;
    //         padding-left: 30px;
    //         position: relative;
    //         .text1 {
    //             position: relative;
    //             span {
    //                 vertical-align: middle;
    //                 &:first-child {
    //                     font-family: Impact;
    //                     color: #ffffff;
    //                     line-height: 30px;
    //                 }

    //                 &:nth-child(2) {
    //                     font-size: 18px;
    //                     font-weight: bold;
    //                     color: #ffffff;
    //                     line-height: 30px;
    //                     margin-right: 6px;
    //                 }
    //             }

    //             &::before {
    //                 content: '';
    //                 width: 11px;
    //                 height: 11px;
    //                 background: #ffffff;
    //                 position: absolute;

    //                 position: absolute;
    //                 left: -29px;
    //                 top: 11px;
    //                 border-radius: 100%;
    //             }
    //         }

    //         &::before {
    //             content: '';
    //             width: 15px;
    //             height: 15px;
    //             border: 1px solid #ffffff;
    //             position: absolute;
    //             left: -2px;
    //             top: 8px;
    //             border-radius: 100%;
    //         }

    //         &::after {
    //             content: '';
    //             width: 0px;
    //             height: 110%;
    //             border-left: 1px dashed #fff;
    //             position: absolute;
    //             left: 6px;
    //             top: 28px;
    //         }
    //     }

    //     /deep/ .swiper-slide {
    //         &:last-child {
    //             .time-item {
    //                 &::after {
    //                     content: none;
    //                 }
    //             }
    //         }
    //     }

    //     /deep/ .swiper-pagination-progressbar {
    //         left: auto;
    //         right: 20px;
    //         height: calc(100% - 60px);
    //         top: 30px;
    //         width: 2px;
    //         .swiper-pagination-progressbar-fill {
    //             background-color: #fff;
    //         }
    //     }
    // }
}
.imgLine {
    position: absolute;
    z-index: 1;
    left: 50%;
    right: 0;
    top: 80px;
    padding-bottom: 100px;
    width: 50vw;
    @media screen and (max-width: 1300px) {
        width: 650px;
    }
    .img-slide {
        height: 400px;
        width: auto;
        display: block;

        @media screen and (max-width: 1300px) {
            width: auto;
            height: 200px;
        }
    }

    /deep/ .swiper-slide {
        width: auto;
    }
}
.img-pagination {
    position: absolute;
    left: 320px;
    bottom: 0;
    font-size: 20px;
    color: #bcc1cc;
    line-height: 24px;
    .flex();
    /deep/ .swiper-pagination-current {
        color: @prim;
        font-size: 20px;
        font-family: Impact;
        padding: 0 5px;
        min-width: 40px;
        text-align: center;
    }

    /deep/ .swiper-pagination-total {
        font-size: 16px;
        font-family: Impact;
        color: #bcc1cc;
        padding: 0 5px;
        text-align: center;
        min-width: 30px;
        align-self: flex-end;
        line-height: 20px;
    }
}
.content_subscript {
    width: 100%;
    padding: 0px 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: relative;
    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #ececed;
        position: absolute;
        left: 0;
        bottom: 10px;
    }
    .content_subscript_con {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content_subscript_con_img {
        width: 22px;
        height: 22px;
        position: relative;
        z-index: 1;
    }
    .content_subscript_con_titles {
        font-size: 14px;
        font-weight: 400;
        color: #01a041;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .content_subscript_con_title {
        font-size: 14px;
        font-weight: 400;
        color: #939599;
        line-height: 24px;
        margin-bottom: 10px;
    }
}
.honor-pagination {
    position: absolute;
    bottom: 0;
    top: auto;
    width: 120px;
    left: 50%;
    transform: translateX(-50%);
}

.el-icon-arrow-left,
.el-icon-arrow-right {
    position: absolute;
    bottom: 295px;
    font-size: 32px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(var(--swiper-theme-color));
    color: #01a041;

    &::after {
        font-family: iconfont;
        font-size: 24px;
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }

    &.swiper-button-disabled {
        color: #bcc1cc;
    }
}

.el-icon-arrow-left {
    left: -43px;
    right: auto;
}
.el-icon-arrow-right {
    right: -43px;
    left: auto;
}

.honor {
    // padding-bottom: 40px;
    img {
        width: 100%;
        height: 280px;
        display: block;
    }

    .name {
        font-size: 14px;
        color: #393939;
        line-height: 24px;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #dcdfe6;
    }
}

.connect {
    height: 700px;
    background-color: #f8f8f8;
    padding-top: 40px;
    .connect-content {
        .flex();

        .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #525866;
            line-height: 24px;
            margin-left: 60px;

            div {
                vertical-align: middle;
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                span {
                    vertical-align: middle;
                }

                i {
                    font-size: 20px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
            }
        }
        position: relative;

        // &::after {
        //     content: '';
        //     width: 60px;
        //     height: 60px;
        //     background-color: fade(@prim, 12);
        //     position: absolute;
        //     top: 0;
        //     right: 50px;
        // }

        // &::before {
        //     width: 30px;
        //     height: 30px;
        //     background-color: @prim;
        //     content: '';
        //     position: absolute;
        //     right: 120px;
        //     top: 70px;
        // }
    }
}
.page-title {
    padding: 0px !important;
    padding-bottom: 60px !important;
}
.honor-content {
    background: #ffffff;
    padding: 100px 50px !important;
    position: relative;
}
.banner_top {
    max-width: 1300px;
    margin: auto;
    position: relative;
    .banner_top_title {
        font-size: 38px;
        font-weight: bold;
        color: #ffffff;
        line-height: 38px;
        position: absolute;
        top: 191px;
        left: 45%;
    }
}
</style>
