<template>
    <baidu-map class="bm-view" ak="YwIFPSy2yt4nKmLw26qBoMB8u5A4KofF" scroll-wheel-zoom :center="center" :zoom="16">
        <bm-marker :position="center" animation="BMAP_ANIMATION_BOUNCE"> </bm-marker>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    </baidu-map>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue';
export default {
    components: {
        BaiduMap,
        BmMarker,
        BmNavigation
    },
    data() {
        return {
            center: {
                lng: 118.884188,
                lat: 32.01995
            }
        };
    }
};
</script>

<style lang="less" scoped>
.bm-view {
    width: 660px;
    height: 360px;
}
</style>
